// import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { getUserByToken } from "../redux/reducers/customAuthReducer";

// For routes that can only be accessed by authenticated users
const AuthGuard = ({ children }) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.authReducer);
  let token = JSON.parse(localStorage.getItem("myToken"));

  if (!token) {
    return <Redirect to="/auth/sign-in" />;
  } else {
    if (!data) {
      dispatch(getUserByToken());
    } else if (data.companies > 1 && !data.currentCompany) {
      return <Redirect to="/auth/select" />;
    }
  }

  return children;
};

export default AuthGuard;
