/* eslint-disable import/first */
import React from "react";

import async from "../../components/Async";

import {
  Briefcase,
  Calendar as CalendarIcon,
  CheckSquare,
  CreditCard,
  Grid,
  Layout,
  List,
  // Monitor,
  ShoppingCart,
  Sliders,
  Activity,
  Home,
  Users,
  FileText,
  Filter,
  Headphones,
  Settings,
} from "react-feather";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Guards
import AuthGuard from "../../components/AuthGuard";

// Auth components
// import SignIn from "../../pages/auth/SignIn";
// import SignUp from "../../pages/auth/SignUp";
// import ResetPassword from "../../pages/auth/ResetPassword";
// import Page404 from "../../pages/auth/Page404";
// import Page500 from "../../pages/auth/Page500";

// Dashboards components
const Dashboard = async(() => import("../../pages/protected/admin/dashboard"));

// // Pages components
// import Blank from "../../pages/pages/Blank";
import InvoiceList from "../../pages/pages/InvoiceList";
import Orders from "../../pages/pages/Orders";
import Setings from "../../pages/pages/Settings";
import Projects from "../../pages/pages/Projects";
import InvoiceDetails from "../../pages/pages/InvoiceDetails";

const Profile = async(() => import("../../pages/pages/Profile"));
const Tasks = async(() => import("../../pages/pages/Tasks"));
const Calendar = async(() => import("../../pages/pages/Calendar"));

import Support from "../../pages/docs/Support";

// Tables components
import SimpleTable from "../../pages/tables/SimpleTable";
import AdvancedTable from "../../pages/tables/AdvancedTable";

// Landing
// import Landing from "../../pages/presentation/Landing";

// Protected routes
import ProtectedPage from "../../pages/protected/ProtectedPage";

const dashboardsRoutes = {
  id: "Dashboard",
  path: "/admin",
  containsHome: true,
  icon: <Activity />,
  component: Dashboard,
  children: null,
};

const companiesRoutes = {
  id: "Companies",
  path: "/invoices",
  icon: <Home />,
  children: [
    {
      path: "/invoices",
      name: "All Companies",
      component: InvoiceList,
    },
    {
      path: "/invoices/detail",
      name: "Add Company",
      component: InvoiceDetails,
    },
  ],

  component: null,
};

const usersRoutes = {
  id: "Users",
  path: "/projects",
  icon: <Users />,
  component: Projects,
  children: null,
};

const accountRoutes = {
  id: "Account Managers",
  path: "/orders",
  icon: <Briefcase />,
  component: Orders,
  children: null,
};

const BlacklistRoutes = {
  id: "Blacklist Settings",
  path: "/tables",
  icon: <List />,
  children: [
    {
      path: "/tables/simple-table",
      name: "Global Blacklist",
      component: SimpleTable,
    },
    {
      path: "/tables/advanced-table",
      name: "Individual Blacklist",
      component: AdvancedTable,
    },
  ],
  component: null,
};

const PlansRoutes = {
  id: "Plans",
  path: "/tasks",
  icon: <CheckSquare />,
  component: Tasks,
  children: null,
};

const LogsRoutes = {
  id: "Company Logs",
  path: "/calendar",
  icon: <FileText />,
  component: Calendar,
  children: null,
};

// const authRoutes = {
//   id: "Auth",
//   path: "/auth",
//   icon: <Users />,
//   children: [
//     {
//       path: "/auth/sign-in",
//       name: "Sign In",
//       component: SignIn,
//     },
//     {
//       path: "/auth/sign-up",
//       name: "Sign Up",
//       component: SignUp,
//     },
//     {
//       path: "/auth/reset-password",
//       name: "Reset Password",
//       component: ResetPassword,
//     },
//     {
//       path: "/auth/404",
//       name: "404 Page",
//       component: Page404,
//     },
//     {
//       path: "/auth/500",
//       name: "500 Page",
//       component: Page500,
//     },
//   ],
//   component: null,
// };

const LeadsRoutes = {
  id: "Leads Management",
  path: "/projects",
  icon: <Filter />,
  children: null,
  component: <Profile />,
};

// const landingRoutes = {
//   id: "Landing Page",
//   path: "/",
//   header: "Docs",
//   icon: <Monitor />,
//   component: Landing,
//   children: null,
// };

const supportRoutes = {
  id: "Support",
  path: "/changelog",
  icon: <Headphones />,
  component: <Support />,
  children: null,
};

const settingsRoutes = {
  id: "Settings",
  name: "Individual Blacklist",
  path: "/pages/settings",
  icon: <Settings />,
  children: null,
  component: Setings,
};

// This route is only visible while signed in
const protectedPageRoutes = {
  id: "Private",
  path: "/private",
  component: ProtectedPage,
  children: null,
  guard: AuthGuard,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardsRoutes,
  companiesRoutes,
  usersRoutes,
  accountRoutes,
  BlacklistRoutes,
  PlansRoutes,
  LogsRoutes,
  LeadsRoutes,
  supportRoutes,
  settingsRoutes,
];

// Routes using the Auth layout
// export const authLayoutRoutes = [authRoutes];

// Routes using the Presentation layout
// export const presentationLayoutRoutes = [landingRoutes];

// Routes that are protected
export const protectedRoutes = [protectedPageRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  dashboardsRoutes,
  companiesRoutes,
  usersRoutes,
  accountRoutes,
  BlacklistRoutes,
  PlansRoutes,
  LogsRoutes,
  // authRoutes,
  LeadsRoutes,
  supportRoutes,
  settingsRoutes,
];
