import axios from "axios";

const authAxios = () => {
  let token = JSON.parse(localStorage.getItem("myToken"));
  const axiosData = {
    baseURL: process.env.REACT_APP_BASE_URL,
  };

  if (token) {
    axiosData.headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
  }

  return axios.create(axiosData);
};

export default authAxios;
