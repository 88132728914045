import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
// import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Paper, Typography, Box, Grid } from "@material-ui/core";
import { setCurrentCompany } from "../../redux/reducers/customAuthReducer";

const useStyles = makeStyles({
  h1Sign: {
    fontSize: "24px",
    fontWeight: "800",
    marginBottom: "2rem",
  },
  btnLink: {
    textDecoration: "none",
    fontSize: "14px",
    fontWeight: "900",
    color: "#23CC94",
  },
  company: {
    textDecoration: "none",
    fontSize: "14px",
    fontWeight: "900",
    color: "white",
    backgroundColor: "#23CC94",
  },
});

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;
  border-radius: 1rem;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;

  margin-top: -13rem;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
  padding: ${(props) => props.theme.spacing(6)}px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;
const SelectComapny = () => {
  const dispatch = useDispatch();
  let { data, error } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (data.currentCompany) {
      console.log(data.currentCompany);
      history.push("/");
    }

    ///
  }, [data.currentCompany, error]);

  const history = useHistory();
  const classes = useStyles();
  const handleClickCompany = (id) => {
    console.log(id);

    dispatch(setCurrentCompany(id));
  };
  return (
    <Box>
      {error && error}

      <Wrapper>
        <Helmet title="select company" />
        <Typography
          component="h1"
          variant="h4"
          align="center"
          gutterBottom
          className={classes.h1Sign}
        >
          Select your <br /> Current Company
        </Typography>
        <Grid container spacing={6}>
          {data.companies &&
            data.companies.map((company) => (
              <Grid item key={company.company._id}>
                <Button
                  // type="button"
                  key={company.company._id}
                  fullWidth
                  variant="contained"
                  className={classes.company}
                  onClick={() => handleClickCompany(company.company._id)}
                >
                  {company.company.company_name}
                </Button>
              </Grid>
            ))}
        </Grid>
      </Wrapper>
      {/* <Box display="block" style={{ textAlign: "center", marginTop: "2rem" }}>
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ color: "white", fontSize: "13px" }}
        >
          Need Intelligence Dashboard account ?
        </Typography>
        <Link to="/auth/sign-up" className={classes.btnLink}>
          Sign up Now
        </Link>
      </Box> */}
    </Box>
  );
};

export default SelectComapny;
