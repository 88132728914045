/* eslint-disable no-const-assign */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { ArrowForwardIos, ArrowBackIos } from "@material-ui/icons";
import * as keys from "../../constants";
import {
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
  Box,
  Grid,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import {
  register,
  resetErrorAndSuccess,
} from "../../redux/reducers/customAuthReducer";
// import PasswordStrengthBar from "react-password-strength-bar";
import { Visibility, VisibilityOff } from "@material-ui/icons";
// const LogoImage = require("/static/img/logo/white.svg");
const theme = createMuiTheme({
  overrides: {
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        fontWeight: "700",
        color: "black",
        fontSize: "15px",
        // "&$focused": {
        //   // increase the specificity for the pseudo class
        //   color: "blue",
        // },
      },
    },
    MuiInput: {
      input: {
        "& .Mui-focused": {
          color: "tomato",
          fontWeight: "bold",
          borderColor: "#868695",
        },
      },
    },
  },
});

const useStyles = makeStyles({
  h1Sign: {
    fontSize: "24px",
    fontWeight: "800",
  },
  h2Sign: {
    fontSize: "14px",
    fontWeight: "600",
  },
  input: {
    padding: "12.5px 14px",
    fontSize: "13px",
    color: "black",
  },
  labelRoot: {
    color: "black",
    fontWeight: "800",
  },
  labelButton: {
    fontSize: "15px",
    fontWeight: "700",
  },
  fotter: {
    textAlign: "center",
    marginTop: "2rem",
  },
  bySignin: {
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "0.75",
    color: "#868695",
  },
  terms: {
    fontSize: "11px",
    fontWeight: "600",
    color: "#868695",
  },
  btnLink: {
    textDecoration: "none",
    fontSize: "14px",
    fontWeight: "900",
    color: "#23CC94",
  },
  buttonDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputPhone: {
    width: "27.5rem",
    height: "2.5rem",
  },
});

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);
// const Divider = styled(MuiDivider)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  margin-top: -13rem;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

function SignUp() {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  // Add these variables to your component to track the state
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [step, setStep] = React.useState(1);
  const [buttonEnabled, setButtonEnabled] = React.useState(false);
  const { data, error, isLoading } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (error) dispatch(resetErrorAndSuccess());

    if (data) {
      localStorage.setItem(keys.USER_TOKEN, JSON.stringify(data.token));
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const butonLabel = [];
  butonLabel[1] = "Sign Up";
  butonLabel[2] = "Next";
  butonLabel[3] = "Finish";

  // const phoneNumberMask = [
  //   "(",
  //   /[1-9]/,
  //   /\d/,
  //   /\d/,
  //   ")",
  //   " ",
  //   /\d/,
  //   /\d/,
  //   /\d/,
  //   "-",
  //   /\d/,
  //   /\d/,
  //   /\d/,
  //   /\d/,
  // ];

  // const setTwoSchema
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().max(30).required("First Name is required"),
    last_name: Yup.string().max(30).required("Last Name is required"),
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    company: Yup.string().max(30).required("Company Name is required"),
    wbsiteUrl: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      )
      .required("Please enter website"),
    phoneNumber: Yup.string().required("Please your phone number"),
    password: Yup.string()
      .min(8, "Must be at least 8 characters")
      .max(255)
      .required("Required"),
    confirmPassword: Yup.string().when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      ),
    }),
  });

  const stepOneValidation = Yup.object().shape({
    first_name: Yup.string().max(30).required("First Name is required"),
    last_name: Yup.string().max(30).required("Last Name is required"),
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
  });

  const stepTowValidation = Yup.object().shape({
    company: Yup.string().max(30).required("Company Name is required"),
    wbsiteUrl: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      )
      .required("Please enter website"),
    phoneNumber: Yup.string().required("Phone number is not valid"),
    // .matches(
    //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //   "Enter correct phone!"
    // )
  });

  const stepThreeValidation = Yup.object().shape({
    password: Yup.string()
      .min(8, "Must be at least 8 characters")
      .max(255)
      .required("Required"),
    confirmPassword: Yup.string().when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      ),
    }),
  });

  function isValidField(currentStepValidation, data) {
    currentStepValidation
      .validate(data)
      .then(function (valid) {
        return setButtonEnabled(true);
      })
      .catch(function (error) {
        return setButtonEnabled(false);
      });
  }

  const validate = async ({
    first_name,
    last_name,
    email,
    company,
    wbsiteUrl,
    phoneNumber,
    password,
    confirmPassword,
  }) => {
    //step One
    if (step === 1)
      await isValidField(stepOneValidation, { first_name, last_name, email });

    //Step2
    if (step === 2) {
      await isValidField(stepTowValidation, {
        company,
        wbsiteUrl,
        phoneNumber,
      });
    }

    //Step 3
    if (step === 3) {
      await isValidField(stepThreeValidation, { password, confirmPassword });
    }

    return {};
  };

  const handleButtonClick = () => {
    if (step < 3) setStep(step + 1);
    setButtonEnabled(false);
  };

  const handleBtnPreviousClick = () => {
    if (step > 1) setStep(step - 1);
    setButtonEnabled(true);
  };

  const onSubmitForm = (values) => {
    console.log(values);

    dispatch(
      register({
        user: {
          first_name: values.last_name,
          last_name: values.last_name,
          email: values.email,
          password: values.password,
          phone_number: values.phoneNumber,
        },
        company: {
          company_name: values.company,
          domain: values.wbsiteUrl,
        },
      })
    );
  };

  return (
    <Box>
      <Wrapper>
        <Helmet title="Sign Up" />
        <Typography
          component="h1"
          variant="h4"
          align="center"
          gutterBottom
          className={classes.h1Sign}
        >
          Sign Up Today
        </Typography>
        <Typography
          component="h2"
          variant="body1"
          align="center"
          className={classes.h2Sign}
        >
          Let's get your (100% free) account set up.
        </Typography>
        <Formik
          validate={validate}
          initialValues={{
            first_name: "",
            last_name: "",
            company: "",
            email: "",
            wbsiteUrl: "",
            phoneNumber: "",
            password: "",
            confirmPassword: "",
            submit: false,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmitForm}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form style={{ marginTop: "19px" }} onSubmit={handleSubmit}>
              {error && (
                <Alert mt={2} mb={1} severity="warning">
                  {error}
                </Alert>
              )}
              <ThemeProvider theme={theme}>
                {step === 1 ? (
                  <>
                    <TextField
                      type="text"
                      name="first_name"
                      label="First Name:"
                      variant="outlined"
                      placeholder="Enter your first name"
                      value={values.first_name}
                      error={Boolean(touched.first_name && errors.first_name)}
                      fullWidth
                      helperText={Boolean(
                        touched.first_name && errors.first_name
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={3}
                      InputLabelProps={{
                        root: classes.labelRoot,
                        shrink: true,
                      }}
                      InputProps={{ classes: { input: classes.input } }}
                      //inputRef={validateDate("name", values.name)}
                    />
                    <TextField
                      type="text"
                      name="last_name"
                      label="Last Name:"
                      variant="outlined"
                      placeholder="Enter your last name"
                      value={values.last_name}
                      error={Boolean(touched.last_name && errors.last_name)}
                      fullWidth
                      helperText={Boolean(
                        touched.last_name && errors.last_name
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={3}
                      InputLabelProps={{
                        root: classes.labelRoot,
                        shrink: true,
                      }}
                      InputProps={{ classes: { input: classes.input } }}
                      //inputRef={validateDate("name", values.name)}
                    />
                    <TextField
                      type="email"
                      name="email"
                      label="Email:"
                      placeholder="Enter your email"
                      variant="outlined"
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={3}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{ classes: { input: classes.input } }}
                    />
                  </>
                ) : (
                  ""
                )}

                {step === 2 ? (
                  <>
                    <TextField
                      type="text"
                      name="company"
                      label="Company Name:"
                      placeholder="Enter your company"
                      variant="outlined"
                      value={values.company}
                      error={Boolean(touched.company && errors.company)}
                      fullWidth
                      helperText={touched.company && errors.company}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={3}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{ classes: { input: classes.input } }}
                    />
                    <TextField
                      type="wbsiteUrl"
                      name="wbsiteUrl"
                      label="Website URL:"
                      placeholder="Enter your Website URL"
                      variant="outlined"
                      value={values.wbsiteUrl}
                      error={Boolean(touched.wbsiteUrl && errors.wbsiteUrl)}
                      fullWidth
                      helperText={touched.wbsiteUrl && errors.wbsiteUrl}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={3}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{ classes: { input: classes.input } }}
                    />

                    <PhoneInput
                      country={"us"}
                      disableDropdown={true}
                      disableCountryCode={true}
                      onlyCountries={["us"]}
                      alwaysDefaultMask={false}
                      name="phoneNumber"
                      label="Phone Number:"
                      //masks={{ us: "(...) ..-..-..", at: "(....) ...-...." }}
                      // prefix=""
                      placeholder="Ex: (821) 999-4578"
                      value={values.phoneNumber}
                      error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                      fullWidth
                      helperText={touched.phoneNumber && errors.phoneNumber}
                      onBlur={handleBlur}
                      onChange={(phone) =>
                        handleChange({
                          target: {
                            name: "phoneNumber",
                            value: phone,
                          },
                        })
                      }
                      my={5}
                      inputStyle={{ width: "27.5rem", height: "2.5rem" }}
                      containerStyle={{ margin: "0.8rem 0 1rem" }}
                      dropdownStyle={{
                        display: "none",
                        marginLeft: "5rem",
                      }}
                    />
                  </>
                ) : (
                  ""
                )}

                {step === 3 ? (
                  <>
                    <TextField
                      type={showPassword ? "text" : "password"}
                      name="password"
                      label="Password"
                      placeholder="Enter your password"
                      variant="outlined"
                      value={values.password}
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={3}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        classes: { input: classes.input },
                      }}
                    />
                    {/* <PasswordStrengthBar
                        password={values.password}
                        style={{
                          width: "21rem",
                          height: "21px",
                          marginLeft: "3px",
                        }}
                      /> */}

                    <TextField
                      type={showPassword ? "text" : "password"}
                      name="confirmPassword"
                      label="Re-enter Password"
                      placeholder="Confirme your password"
                      variant="outlined"
                      value={values.confirmPassword}
                      error={Boolean(
                        touched.confirmPassword && errors.confirmPassword
                      )}
                      fullWidth
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={3}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        classes: { input: classes.input },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <Visibility
                                  style={{ width: "1.2rem", height: "1.2rem" }}
                                />
                              ) : (
                                <VisibilityOff
                                  style={{ width: "1.2rem", height: "1.2rem" }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </>
                ) : (
                  ""
                )}
              </ThemeProvider>
              <Grid container direction="row" align="center" spacing={4}>
                {step !== 1 && (
                  <Grid item md={4}>
                    <Button
                      style={{
                        borderRadius: "10px",
                        height: "3.6rem",
                        backgroundColor: "#23CC94",
                        color: "white",
                      }}
                      classes={{ label: classes.labelButton }}
                      // type="submit"
                      variant="contained"
                      // disabled={isSubmitting}
                      // expand={expanded}
                      onClick={handleBtnPreviousClick}
                      // aria-expanded={expanded}
                      fullWidth
                      startIcon={<ArrowBackIos style={{ marginLeft: "6px" }} />}
                    >
                      Previous
                    </Button>
                  </Grid>
                )}

                <Grid item md={step === 1 ? 12 : 8}>
                  <Button
                    style={{
                      borderRadius: "10px",
                      height: "3.6rem",
                      backgroundColor: buttonEnabled ? "#23CC94" : "#cccccc",
                      color: "white",
                      // cursor: !isSubmitting ? "not-allowed" : "pointer",
                    }}
                    classes={{ label: classes.labelButton }}
                    type="submit"
                    variant="contained"
                    disabled={!isSubmitting && !buttonEnabled}
                    onClick={step < 3 ? handleButtonClick : ""}
                    // onClick={handleButtonClick}
                    // aria-expanded={expanded}
                    fullWidth
                    endIcon={
                      step === 3 ? (
                        ""
                      ) : (
                        <ArrowForwardIos style={{ marginLeft: "6px" }} />
                      )
                    }
                  >
                    {butonLabel[step] === "Finish" && isLoading ? (
                      <CircularProgress size="2rem" color="inherit" />
                    ) : (
                      butonLabel[step]
                    )}
                  </Button>
                </Grid>
              </Grid>
              <div className={classes.fotter}>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  className={classes.bySignin}
                >
                  By signing up you agree to Intelligencedashboard.com
                </Typography>
                <Typography variant="subtitle2" className={classes.terms}>
                  <span style={{ color: "#6320EE", cursor: "pointer" }}>
                    {" "}
                    Term of service
                  </span>{" "}
                  and{" "}
                  <span style={{ color: "#6320EE", cursor: "pointer" }}>
                    Privacy policy
                  </span>
                </Typography>
              </div>
            </form>
          )}
        </Formik>
      </Wrapper>
      <Box display="block" style={{ textAlign: "center", marginTop: "2rem" }}>
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ color: "white", fontSize: "13px" }}
        >
          Already have an account?
        </Typography>
        <Link to="/auth/sign-in" className={classes.btnLink}>
          Sign in
        </Link>
      </Box>
    </Box>
  );
}

export default SignUp;
