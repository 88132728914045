import React from "react";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";

import {
  Button as MuiButton,
  MenuItem,
  Select,
  FormControl,
} from "@material-ui/core";

import {
  Loop as LoopIcon,
  FilterList as FilterListIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { DateTime } from "luxon";

import { spacing } from "@material-ui/system";

const getDate = (type) => {
  var dt = DateTime.now();
  const data = {};
  switch (type) {
    case "today": {
      data.start_date = dt.toFormat("yyyy-MM-dd").toLocaleString();
      data.end_date = dt.toFormat("yyyy-MM-dd").toLocaleString();
      break;
    }
    case "yesterday": {
      data.start_date = dt
        .minus({ days: 1 })
        .toFormat("yyyy-MM-dd")
        .toLocaleString();
      data.end_date = dt
        .minus({ days: 1 })
        .toFormat("yyyy-MM-dd")
        .toLocaleString();
      break;
    }
    case "last-7-days": {
      data.start_date = dt
        .minus({ days: 7 })
        .toFormat("yyyy-MM-dd")
        .toLocaleString();
      data.end_date = dt.toFormat("yyyy-MM-dd").toLocaleString();

      break;
    }
    case "last-30-days": {
      data.start_date = dt
        .minus({ days: 30 })
        .toFormat("yyyy-MM-dd")
        .toLocaleString();
      data.end_date = dt.toFormat("yyyy-MM-dd").toLocaleString();
      console.log(data);
      break;
    }
    case "this-month": {
      data.start_date = dt.toFormat("yyyy-MM-01").toLocaleString();
      data.end_date = dt.toFormat("yyyy-MM-dd").toLocaleString();
      break;
    }
    case "last-month": {
      data.start_date = dt
        .minus({ months: 1 })
        .toFormat("yyyy-MM-01")
        .toLocaleString();
      const nbrDays = dt.minus({ months: 1 }).daysInMonth;
      data.end_date = dt
        .minus({ months: 1 })
        .toFormat("yyyy-MM-" + nbrDays)
        .toLocaleString();

      break;
    }
    case "this-year": {
      data.start_date = dt.toFormat("yyyy-01-01").toLocaleString();
      data.end_date = dt.toFormat("yyyy-MM-dd").toLocaleString();
      break;
    }
    case "last-year": {
      data.start_date = dt
        .minus({ years: 1 })
        .toFormat("yyyy-01-01")
        .toLocaleString();

      data.end_date = dt
        .minus({ years: 1 })
        .toFormat("yyyy-12-31")
        .toLocaleString();

      break;
    }
    default:
  }

  return data;
};

const useStyle = makeStyles({
  button: {
    backgroundColor: "#fff",
  },
  shrink: {
    display: "none",
  },
  input: {
    padding: "8.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  paperSelect: {
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    // backgroundColor: "red",
  },
  select: {
    border: "1px solid #6A74C9",
    borderRadius: "4px",
    color: "#6A74C9",
    // backgroundColor: "white"
    fontWeight: "600",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  selectMenu: {
    paddingLeft: "14px",
  },
});

const Button = styled(MuiButton)(spacing);

const SmallButton = styled(Button)`
  padding: 4px;
  min-width: 0;
  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;

const Actions = ({ toDispatch }) => {
  const classes = useStyle();
  const [date, setDate] = React.useState("last-30-days");
  const dispatch = useDispatch();

  React.useEffect(() => {
    // if (date) {
    //   setDate("last-30-days");
    // }
    const filter = JSON.parse(localStorage.getItem("myFilter"));
    console.log("filter is ", filter);
    if (filter) {
      dispatch(toDispatch(filter));
    }
  }, []);

  function handleChange(event) {
    setDate(event.target.value);
    const filter = getDate(event.target.value);

    console.log(filter);
    //create a new cookie named "filter"
    localStorage.setItem("myFilter", JSON.stringify(filter));
    dispatch(toDispatch(filter));
  }

  return (
    <React.Fragment>
      <SmallButton size="small" mr={2} color={"secondary"}>
        <FilterListIcon />
      </SmallButton>
      <SmallButton size="small" mr={2} color={"secondary"}>
        <LoopIcon />
      </SmallButton>

      <FormControl style={{ minWidth: "15rem" }}>
        <Select
          classes={{ root: classes.input }}
          inputlabelprops={{ shrink: false }}
          variant="outlined"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={date}
          onChange={handleChange}
          MenuProps={{
            classes: { paper: classes.paperSelect },
            variant: "menu",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          className={classes.select}

          // key={date.key}
        >
          {/* <MenuItem value={"last-30-days"}>
            Recent (Last 30 Days + Today)
          </MenuItem> */}
          <MenuItem value={"today"}>Today</MenuItem>
          <MenuItem value={"yesterday"}>Yesterday</MenuItem>
          <MenuItem value={"last-7-days"}>Last 7 Days</MenuItem>
          <MenuItem value={"last-30-days"}> Last 30 Days </MenuItem>
          <MenuItem value={"this-month"}>This Month</MenuItem>
          <MenuItem value={"last-month"}>Last Month</MenuItem>
          <MenuItem value={"this-year"}>This Year</MenuItem>
          <MenuItem value={"last-year"}>Last Year</MenuItem>
        </Select>
      </FormControl>
    </React.Fragment>
  );
};

export default Actions;
