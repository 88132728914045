import React from "react";
import styled from "styled-components/macro";

import {
  Box,
  Card,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
  Button,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import { makeStyles } from "@material-ui/core/styles";

import { spacing } from "@material-ui/system";

const Typography = styled(MuiTypography)(spacing);

const useStyles = makeStyles({
  card: {
    border: "1.5px solid #fff",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
    transition: "border 1s, color 1s",
    "&:hover": {
      color: "#388E3C",
      border: "1.5px solid #388E3C",
    },
  },
  button: {
    position: "absolute",
    top: "16px",
    right: "16px",
    padding: "4px 10px",
    fontSize: "85%",
    backgroundColor: "#6a74c9",

    color: "#fff",
    marginbottom: "16px",
    "&:hover": {
      backgroundColor: "#3d4378",
    },
  },
});
const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const Stats = ({ title, amount, chip, percentageText, percentagecolor }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography
          variant="h5"
          style={{ fontWeight: "700", color: "black" }}
          mb={2}
        >
          {title}
        </Typography>
        <Typography variant="h1" mb={3}>
          <Box fontWeight="600">{amount}</Box>
        </Typography>
        <Button className={classes.button} endIcon={<RefreshIcon />}>
          Verify
        </Button>
      </CardContent>
    </Card>
  );
};

export default Stats;
