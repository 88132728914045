import React, { useEffect } from "react";
import styled, { withTheme } from "styled-components/macro";
import { useSelector, useDispatch } from "react-redux";
// import { addUser } from "../../../../../redux/reducers/customAuthReducer";

import { Helmet } from "react-helmet-async";

import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Snackbar,
} from "@material-ui/core";

import { green, red } from "@material-ui/core/colors";

import { spacing } from "@material-ui/system";

import Actions from "./Actions";
import BarChart from "../../../components/BarChart";
import Stats from "./Stats";
import Rate from "./Rate";
import StateLeads from "./StateLeads";
import TrafficSource from "./TrafficSource";
import DoughnutChart from "./DoughnutChart";
import DoughnutChartDivice from "./DoughnutChartDivice";
import WebsiteHealth from "./WesbiteHealth";

import { getOverviews } from "../../../../../redux/reducers/websiteMetricsReducer";
import { Alert as MuiAlert } from "@material-ui/lab";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Divider = styled(MuiDivider)(spacing);
const Spacer = styled.div(spacing);

const Typography = styled(MuiTypography)(spacing);

const Dashboard = ({ theme }) => {
  const [open, setOpen] = React.useState(false);
  const { error, data } = useSelector((state) => state.websiteMetricsReducer);
  const dispatch = useDispatch();

  // const handleClick = () => {
  //   setOpen(true);
  // };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    const filter = JSON.parse(localStorage.getItem("myFilter"));
    console.log("filter", filter);

    dispatch(getOverviews(filter));
    if (error) setOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {error && (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {error}
          </Alert>
        </Snackbar>
      )}

      <Helmet title="Website Matrics" />
      <Grid justify="space-between" container spacing={2}>
        <Grid item>
          <Typography
            variant="h3"
            style={{
              marginBottom: "10px",
              fontWeight: "700",
              fontSize: "20px",
            }}
            gutterBottom
          >
            Website Matrics
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ marginTop: "-10px", fontWeight: "600" }}
          >
            Welcome back, Client's <span>Fist Name!</span> We've missed you.
            <span role="img" aria-label="Waving Hand Sign">
              👋
            </span>
          </Typography>
        </Grid>

        <Grid item>
          <Actions toDispatch={getOverviews} />
        </Grid>
      </Grid>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} lg={5}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              style={{
                marginBottom: "10px",
                fontWeight: "700",
                fontSize: "20px",
              }}
              gutterBottom
            >
              Overview
            </Typography>
            <Typography
              variant="subtitle2"
              style={{
                marginTop: "-10px",
                marginBottom: "10px",
                fontWeight: "600",
              }}
            >
              This Month of July
            </Typography>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={6}>
              <Stats
                title="Total People"
                amount={data.total_people}
                chip="Today"
                percentageText={data.total_people_percentage}
                percentagecolor={green[500]}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stats
                title="Average Actions"
                amount={Number(isNaN(data.averageActions))}
                chip="Today"
                percentageText={data.averageActions_percentage}
                percentagecolor={red[500]}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stats
                title="Return Visitors"
                amount={data.return_visitors}
                chip="Today"
                percentageText={data.return_visitors_percentage}
                percentagecolor={red[500]}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stats
                title="New Visitors"
                amount={data.new_visitors}
                chip="Today"
                percentageText={data.new_visitors_percentage}
                percentagecolor={green[500]}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Rate
                title="Conversion Rate"
                amount="5.5%"
                chip="Today"
                percentageText="+6%"
                percentagecolor={green[500]}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={7}>
          <BarChart chart={data.chart} />
        </Grid>
      </Grid>
      <Spacer mb={6} />

      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            style={{
              marginBottom: "10px",
              fontWeight: "700",
              fontSize: "20px",
            }}
            gutterBottom
          >
            Website Leads
          </Typography>
          <Typography
            variant="subtitle2"
            style={{
              marginTop: "-10px",
              marginBottom: "-7px",
              fontWeight: "600",
            }}
          >
            This Month of July
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <StateLeads
            title="People"
            amount="17,244"
            chip="Today"
            percentageText="+26%"
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <StateLeads
            title="Total Leads"
            amount="603"
            chip="Today"
            percentageText="-14%"
            percentagecolor={red[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <StateLeads
            title="Conversion Rate"
            amount="3.49%"
            chip="Today"
            percentageText="+18%"
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <StateLeads
            title="Cost Per Lead"
            amount="$112.9"
            chip="Yearly"
            percentageText="-9%"
            percentagecolor={red[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <StateLeads
            title="Cost"
            amount="$74,772"
            chip="Yearly"
            percentageText="-9%"
            percentagecolor={red[500]}
          />
        </Grid>
      </Grid>
      <Spacer mb={10} />
      <Grid container spacing={6}>
        <Grid item xs={12} lg={5}>
          <TrafficSource />
        </Grid>
        <Grid item xs={12} lg={3}>
          <DoughnutChart />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DoughnutChartDivice />
        </Grid>
      </Grid>

      <Spacer mb={3} />

      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            style={{
              marginBottom: "10px",
              fontWeight: "700",
              fontSize: "20px",
            }}
            gutterBottom
          >
            Website Health
          </Typography>
          <Typography
            variant="subtitle2"
            style={{
              marginTop: "-10px",
              marginBottom: "-7px",
              fontWeight: "600",
            }}
          >
            This Month of July
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <WebsiteHealth
            title="Uptime Monitoring"
            amount="100%"
            chip="Verify"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <WebsiteHealth
            title="Security Monitoring"
            amount="100%"
            chip="Verify"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <WebsiteHealth title="Hosting" amount="Perfect" chip="Verify" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <WebsiteHealth title="Website Speed" amount="3.4s" chip="Verify" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <WebsiteHealth title="Lead Capture" amount="Perfect" chip="Verify" />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default withTheme(Dashboard);
