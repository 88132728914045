import React from "react";
import styled from "styled-components/macro";

import {
  Button as MuiButton,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";

import {
  Loop as LoopIcon,
  FilterList as FilterListIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import { spacing } from "@material-ui/system";

const useStyle = makeStyles((theme) => ({
  button: {
    backgroundColor: "#fff",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "15rem",
    marginTop: "-5px",
  },
  labelView: {
    color: "#6A74C9",
    height: "31px",
  },
  shrink: {
    display: "none",
  },
  paperSelect: {
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    // backgroundColor: "red",
  },
  select: {
    border: "1px solid #6A74C9",
    borderRadius: "4px",
    color: "#6A74C9",
    // backgroundColor: "white"
    fontWeight: "600",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  selectMenu: {
    paddingLeft: "14px",
  },
  input: {
    padding: "8.5px 14px",
    backgroundColor: "#F9F9FC",
    borderRadius: "4px",
  },
}));

const Button = styled(MuiButton)(spacing);

const SmallButton = styled(Button)`
  padding: 4px;
  min-width: 0;
  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;

function Actions() {
  const classes = useStyle();

  const [age, setAge] = React.useState("35");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <React.Fragment>
      <SmallButton size="small" mr={2} color={"secondary"}>
        <FilterListIcon />
      </SmallButton>
      <SmallButton size="small" mr={2} color={"secondary"}>
        <LoopIcon />
      </SmallButton>
      <FormControl classes={{ root: classes.formControl }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          // defaultValue={{}}
          label="View Only Access"
          onChange={handleChange}
          disableUnderline
          MenuProps={{
            classes: { paper: classes.paperSelect },
            variant: "menu",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          className={classes.select}
          classes={{ root: classes.input, select: classes.selectMenu }}

          // getContentAnchorEl={null}
          // anchorOrigin={{ vertical: "bottom", horizontal: "top" }}
          // transformOrigin={{ vertical: "bottom", horizontal: "bottom" }}
        >
          <MenuItem value={80}>Custom</MenuItem>
          <MenuItem value={20}>Reccent (Last 30 Dyas + Today)</MenuItem>
          <MenuItem value={30}>Yesterday</MenuItem>
          <MenuItem value={31}>Last 7 Days</MenuItem>
          <MenuItem value={32}>Last 30 Days</MenuItem>
          <MenuItem value={33}>This Month</MenuItem>
          <MenuItem value={34}>Last Month</MenuItem>
          <MenuItem value={35}>This Year</MenuItem>
          <MenuItem value={36}>Last Year</MenuItem>
        </Select>
      </FormControl>
    </React.Fragment>
  );
}

export default Actions;
