import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import AppAdmine from "./AppAdmin";
import "./mocks";
// import dotenv from "dotenv";
import { Provider } from "react-redux";
import store from "./redux/store/index";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

// const result = dotenv.config();

// if (result.error) {
//   throw result.error;
// }

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/">
          <App />
        </Route>
        <Route path="/admin">
          <AppAdmine />
        </Route>
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
