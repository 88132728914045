import { combineReducers } from "redux";

import themeReducer from "./customThemeReducer";
//import authReducer from "./authReducer";
import authReducer from "./customAuthReducer";
import websiteMetricsReducer from "./websiteMetricsReducer";

export const rootReducer = combineReducers({
  themeReducer,
  authReducer,
  websiteMetricsReducer,
});
