import React from "react";
import styled, { withTheme } from "styled-components/macro";

import {
  CardContent,
  Card as MuiCard,
  Typography,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { makeStyles } from "@material-ui/core/styles";
import { Bar } from "react-chartjs-2";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const themes = createMuiTheme({
  overrides: {
    MuiCheckbox: {
      colorPrimary: {
        color: "# custom color",
        "&$checked": {
          color: "#6320EE",
        },
      },
    },
  },
});

const ChartWrapper = styled.div`
  height: 344px;
  width: 100%;
`;
const useStyles = makeStyles({
  traffic: {
    borderBottom: "1px solid #E8EEF1",
  },
  Card: {
    height: "501px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },

  shrink: {
    display: "none",
  },
  input: {
    padding: "8.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  paperSelect: {
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    // backgroundColor: "red",
  },
  select: {
    border: "1px solid #6A74C9",
    borderRadius: "4px",
    color: "#6A74C9",
    // backgroundColor: "white"
    fontWeight: "600",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  selectMenu: {
    paddingLeft: "14px",
  },
  labelCheck: {
    fontWeight: "bold",
    fontSize: "14px",
  },
});

function BarChart({ theme, chart }) {
  const data = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "rgba(245, 161, 39, 0.27)");
    gradient.addColorStop(0.88, "rgba(255, 255, 255, 0.27)");
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, 150, 100);

    return {
      labels: chart ? chart.date : [],
      datasets: [
        {
          yAxisID: "A",
          type: "bar",
          label: "B",
          backgroundColor: "#9A68FC",
          borderColor: theme.palette.grey[300],
          hoverBackgroundColor: theme.palette.grey[300],
          hoverBorderColor: theme.palette.grey[300],
          data: chart ? chart.calls : [21, 45, 45, 78, 63, 65, 78, 87],
          barPercentage: 0.75,
          categoryPercentage: 0.5,
          fill: true,
          tension: 0.4,
        },

        {
          yAxisID: "A",
          type: "bar",
          label: "A",
          backgroundColor: theme.palette.secondary.main,
          borderColor: theme.palette.secondary.main,
          hoverBackgroundColor: theme.palette.secondary.main,
          hoverBorderColor: theme.palette.secondary.main,
          data: chart ? chart.leads : [21, 5, 45, 78, 63, 645, 78, 87],
          barPercentage: 0.75,
          categoryPercentage: 0.5,
          fill: true,
          tension: 0.4,
        },
        {
          yAxisID: "B",
          type: "line",
          label: "Line Dataset",
          // borderColor: theme.palette.grey[300],
          borderColor: "#FF9800",
          fill: true,
          //tension: true,
          backgroundColor: gradient,

          // backgroundColor: "linear-gradient(red, yellow, blue);",
          //hoverBorderColor: "#FF9800",
          data: chart ? chart.metrics : [21, 45, 45, 78, 63, 65, 78, 87],
          lineTension: 0.5,
        },
      ],
    };
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      // yAxes: [
      //   {
      //     // gridLines: {
      //     //   display: false,
      //     // },
      //     // stacked: false,
      //     // ticks: {
      //     //   stepSize: 20,
      //     // },
      //   },
      // ],
      yAxes: [
        {
          id: "A",
          type: "linear",
          position: "left",
        },
        {
          id: "B",
          type: "linear",
          position: "right",
          gridLines: {
            display: false,
          },
        },
      ],

      xAxes: [
        {
          stacked: false,
          gridLines: {
            color: "transparent",
          },
        },
      ],
    },
  };

  const classes = useStyles();
  const [date, setDate] = React.useState("27");

  const handleChangeDate = (event) => {
    setDate(event.target.value);
  };
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  // React.useEffect(() => {
  //   if (state.checkedA) {

  //   }
  // }, [state.checkedA]);

  return (
    <Card mb={1} className={classes.Card}>
      <CardContent>
        <Grid
          justify="space-between"
          container
          spacing={6}
          className={classes.traffic}
        >
          <Grid item>
            <Typography
              variant="h5"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              Traffic Report
            </Typography>
            <Typography variant="body2" gutterBottom>
              Monitor traffic across all of your marketing effort
            </Typography>
          </Grid>

          <Grid item>
            <FormControl style={{ minWidth: "15rem" }}>
              <Select
                classes={{ root: classes.input }}
                inputlabelprops={{ shrink: false }}
                variant="outlined"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={date}
                onChange={handleChangeDate}
                MenuProps={{
                  classes: {
                    paper: classes.paperSelect,
                    // select: classes.selectMenu,
                  },
                  variant: "menu",
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                className={classes.select}
              >
                <MenuItem value={10}>Custom</MenuItem>
                <MenuItem value={11}>Recent (Last 30 Days + Today)</MenuItem>
                <MenuItem value={20}>Today</MenuItem>
                <MenuItem value={21}>Yesterday</MenuItem>
                <MenuItem value={29}>Last 7 Days</MenuItem>
                <MenuItem value={22}>Last 30 Days</MenuItem>
                <MenuItem value={24}>Last 30 Days</MenuItem>
                <MenuItem value={25}>This Month</MenuItem>
                <MenuItem value={26}>Last Month</MenuItem>
                <MenuItem value={27}>This Year</MenuItem>
                <MenuItem value={28}>Last Year</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Spacer mb={5} />
        <ThemeProvider theme={themes}>
          <FormGroup row style={{ marginLeft: "5px" }}>
            <FormControlLabel
              control={
                <>
                  <Checkbox
                    checked={state.checkedA}
                    onChange={handleChange}
                    name="checkedA"
                    color="primary"
                  />
                </>
              }
              classes={{ label: classes.labelCheck }}
              label="All"
              style={{ marginRight: "72px", fontWeight: "bold" }}
            />
            <FormControlLabel
              control={
                <>
                  <Checkbox
                    checked={state.checkedB}
                    onChange={handleChange}
                    name="checkedB"
                    color="primary"
                  />
                  <span
                    style={{
                      backgroundColor: "#9A68FC",
                      width: "9px",
                      height: "9px",
                      left: "-4%",
                      position: "relative",
                      borderRadius: "50%",
                      margin: "3px",
                    }}
                  ></span>
                </>
              }
              classes={{ label: classes.labelCheck }}
              label="Phone Calls"
              style={{ marginRight: "72px" }}
            />
            <FormControlLabel
              control={
                <>
                  <Checkbox
                    checked={state.checkedF}
                    onChange={handleChange}
                    name="checkedF"
                    color="primary"
                  />
                  <span
                    style={{
                      backgroundColor: "#4782DA",
                      width: "9px",
                      height: "9px",
                      left: "-4%",
                      position: "relative",
                      borderRadius: "50%",
                      margin: "3px",
                    }}
                  ></span>
                </>
              }
              classes={{ label: classes.labelCheck }}
              label="Website Leads"
              style={{ marginRight: "75px" }}
            />
            <FormControlLabel
              control={
                <>
                  <Checkbox
                    checked={state.checkedG}
                    onChange={handleChange}
                    name="checkedG"
                    color="primary"
                  />
                  <span
                    style={{
                      backgroundColor: "#FF9800",
                      width: "19px",
                      height: "3.3px",
                      left: "-4%",
                      position: "relative",
                      margin: "3px",
                    }}
                  ></span>
                </>
              }
              classes={{ label: classes.labelCheck }}
              label="Traffic"
              style={{ marginRight: "72px", fontWeight: "bold" }}
            />
          </FormGroup>
        </ThemeProvider>
        <Spacer mb={5} />
        <ChartWrapper>
          <Bar data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
}

export default withTheme(BarChart);
