import { createSlice } from "@reduxjs/toolkit";
import { apiRequest } from "../actions/apiActions";

const metricsSlice = createSlice({
  name: "metrics",
  initialState: {
    data: {},
    isLoading: false,
    error: null,
    messageSucceeded: null,
  },
  reducers: {
    requestStarted: (state, action) => {
      state.isLoading = true;
      state.error = null;
      state.messageSucceeded = null;
    },
    requestSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.messageSucceeded = "data is success";
    },
    requestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export default metricsSlice.reducer;

export const { requestStarted, requestSuccess, requestFailed } =
  metricsSlice.actions;

export const getOverviews = (data) => {
  return apiRequest({
    url: "/integrations/website-analytics",
    data,
    method: "POST",
    onStart: requestStarted.type,
    onSuccess: requestSuccess.type,
    onError: requestFailed.type,
  });
};
